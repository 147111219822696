import React from 'react'
import { Card, Col, Row, Table, Button } from 'react-bootstrap'

const ClientList = () => {
    return (
        <>
            <Row className='gx-3'>
                <Col md={6} className='mb-3 '>
                    <Card className='h-100'>
                        <Card.Body>
                            <Table responsive hover size='sm' className='fs--1 fw-light mt-3'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Property Owned </td>
                                        <td className='text-end'>07</td>
                                    </tr>
                                    <tr>
                                        <td>Property Rented</td>
                                        <td className='text-end'>01</td>
                                    </tr>
                                    <tr>
                                        <td>User Since</td>
                                        <td className='text-end'>01 june 2023</td>
                                    </tr>
                                    <tr>
                                        <td>Complete Count</td>
                                        <td className='text-end'>00</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-end align-items-end" style={{ height: "106px" }}>
                                <Button variant='primary' size='sm' className='mt-3 fs--1 fw-light'>Pending Approvals</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} className='mb-3'>
                    <Card>
                        <Card.Body>
                            <Table responsive hover size='sm' className='fs--1 fw-light mt-3'>
                                <thead>
                                    <tr>
                                        <th>Owned Property</th>
                                        <th className='text-end'>Added On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Koshi Niwas</td>
                                        <td className='text-end'>07 jun 2023</td>
                                    </tr>
                                    <tr>
                                        <td>Kamla Niwas</td>
                                        <td className='text-end'>01 feb 2023</td>
                                    </tr>
                                    <tr>
                                        <td>Radha Niwas</td>
                                        <td className='text-end'>01 june 2023</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <Table responsive hover size='sm' className='fs--1 fw-light mt-3'>
                                <thead>
                                    <tr>
                                        <th>Rented Property</th>
                                        <th className='text-end'>Rented On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Koshi Niwas</td>
                                        <td className='text-end'>07 jun 2023</td>
                                    </tr>
                                    <tr>
                                        <td>Kamla Niwas</td>
                                        <td className='text-end'>01 feb 2023</td>
                                    </tr>
                                    <tr>
                                        <td>Radha Niwas</td>
                                        <td className='text-end'>01 june 2023</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ClientList
