import ActionButton from 'theme-components/common/ActionButton'
import React from 'react'
import { Card, Table } from 'react-bootstrap'

const ClientApproved = () => {
    return (
        <>
            <Card>
                <Card.Body>
                    <Table responsive hover size='sm' className='fs--1 fw-light'>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Kamla Niwas</td>
                                <td className="text-end">
                                    <ActionButton icon="check" title="Approved" variant="action" className="p-0 me-2" />
                                    <ActionButton icon="times" title="Cancel" variant="action" className="p-0" />
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Nirala Niwas</td>
                                <td className="text-end">
                                    <ActionButton icon="check" title="Approved" variant="action" className="p-0 me-2" />
                                    <ActionButton icon="times" title="Cancel" variant="action" className="p-0" />
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Shanti Niwas</td>
                                <td className="text-end">
                                    <ActionButton icon="check" title="Approved" variant="action" className="p-0 me-2" />
                                    <ActionButton icon="times" title="Cancel" variant="action" className="p-0" />
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Koshi Niwas</td>
                                <td className="text-end">
                                    <ActionButton icon="check" title="Approved" variant="action" className="p-0 me-2" />
                                    <ActionButton icon="times" title="Cancel" variant="action" className="p-0" />
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Radha Niwas</td>
                                <td className="text-end">
                                    <ActionButton icon="check" title="Approved" variant="action" className="p-0 me-2" />
                                    <ActionButton icon="times" title="Cancel" variant="action" className="p-0" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </Card.Body>
            </Card>
        </>
    )
}

export default ClientApproved
