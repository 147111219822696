import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { getGenders, getRoles } from 'services/apis/user-lookups-service';
import { RegisterUser } from '../../services/apis/user-service';
import { tostStyle } from '../../helpers/utils';
import { useLoading } from "../../context/loading-context";
import CustomLoader from 'theme-components/common/custom-loader';
import { AddValidation } from 'services/validation/form-validation';
import { tokenHandler } from 'services/validation/api-handler';
import RedirectModal from 'app-components/redirect-modal';
import { AddUserMessage, AddUserType } from 'helpers/messages';

const AddUser = () => {
    const { loading, setLoading } = useLoading();
    const [rolesdata, setRolesData] = useState();
    const [genderData, setGenderData] = useState();
    const [redirectModal, setRedirectModal] = useState(false);
    const users = useNavigate();
    const login = useNavigate();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const getLookupsData = async () => {
        setLoading(true);
        const responseGender = await getGenders();
        const responseRoles = await getRoles();
        if (responseGender != null) {
            setGenderData(responseGender.data);
        }
        if (responseRoles != null) {
            setRolesData(responseRoles.data);
        }
        setLoading(false);
    }
    useEffect(() => {
        getLookupsData();
    }, [])

    const onSubmit = async (data) => {
        if (tokenHandler() == true || tokenHandler() == undefined) {
            setRedirectModal(true);
            setTimeout(() => {
                setRedirectModal(false);
                login("/login");
            }, 10000);
        }
        else {
            const payLoad = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                genderId: data.genderId,
                roles: [data.roles],
            };
            const responseRegister = await RegisterUser(payLoad);
            if (responseRegister != null) {
                const { isSuccess, successMessage, errorMessages } = responseRegister;
                if (isSuccess == true) {
                    toast.success(successMessage, tostStyle);
                    reset();
                }
                else { errorMessages.forEach((err) => { toast.error(err.message, tostStyle); }); }
            }
        }
    };

    let firstNameError;
    if (errors?.firstName?.type === AddUserType.required) { firstNameError = AddUserMessage.firstName.required }
    if (errors?.firstName?.type === AddUserType.pattern) { firstNameError = AddUserMessage.firstName.pattern }
    if (errors?.firstName?.type === AddUserType.minLength) { firstNameError = AddUserMessage.firstName.length }

    let lastNameError;
    if (errors?.lastName?.type === AddUserType.required) { lastNameError = AddUserMessage.lastName.required }
    if (errors?.lastName?.type === AddUserType.pattern) { lastNameError = AddUserMessage.lastName.pattern }
    if (errors?.lastName?.type === AddUserType.minLength) { lastNameError = AddUserMessage.lastName.length }

    let emailError;
    if (errors?.email?.type === AddUserType.required) { emailError = AddUserMessage.email.required }
    if (errors?.email?.type === AddUserType.pattern) { emailError = AddUserMessage.email.pattern }

    let mobileError;
    if (errors?.mobile?.type === AddUserType.pattern) { mobileError = AddUserMessage.mobile.pattern }

    let roleError;
    if (errors?.roles?.type === AddUserType.required) { roleError = AddUserMessage.role.required }

    return (
        <>
            {redirectModal ? <RedirectModal show={redirectModal} /> : ""}

            <Card>
                <Card.Header as="h5">Create a user</Card.Header>
                <Card.Body className="bg-light">
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3 g-3">
                            <Col md={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label className='fs--1'>First Name</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter First Name" name="firstName" isInvalid={!!errors.firstName} {...register("firstName", AddValidation.name)} />
                                    <Form.Control.Feedback type="invalid">
                                        {firstNameError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label className='fs--1'>Last Name</Form.Label>
                                    <Form.Control size="sm" type="text" placeholder="Enter Last Name" name="lastName" isInvalid={!!errors.lastName} {...register("lastName", AddValidation.name)} />
                                    <Form.Control.Feedback type="invalid">
                                        {lastNameError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Form.Group>
                                    <Form.Label className='fs--1'>Email</Form.Label>
                                    <Form.Control size="sm" type="email" placeholder="Enter email" name="email" isInvalid={!!errors.email} {...register("email", AddValidation.email)} />
                                    <Form.Control.Feedback type="invalid">
                                        {emailError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Form.Group >
                                    <Form.Label className='fs--1'>Mobile No.(optional)</Form.Label>
                                    <Form.Control size="sm" type="number" placeholder="Enter Mobile NO." name='mobile' isInvalid={!!errors.mobile} {...register("mobile", AddValidation.mobile)} />
                                    <Form.Control.Feedback type="invalid">
                                        {mobileError}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-2">
                                <Form.Group >
                                    <Form.Label className='fs--1'>Role</Form.Label>
                                    <Form.Select size="sm" aria-label="Default select example" name="roles" isInvalid={!!errors.roles} {...register("roles", AddValidation.required)}>
                                        <option value="">Select Role</option>
                                        {
                                            rolesdata &&
                                            rolesdata.map((data) => {
                                                const { roleName, roleId } = data;
                                                return (
                                                    <option key={roleId} value={roleId}>{roleName}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {roleError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-2 mt-0 mt-md-3">
                                <Form.Group >
                                    <Form.Label className='fs--1'>Gender</Form.Label>
                                    <br />
                                    {
                                        loading ? <CustomLoader /> :
                                            genderData &&
                                            genderData.map((data) => {
                                                const { genderId, type } = data;
                                                return (
                                                    <Form.Check inline key={genderId} type='radio' label={type} value={genderId} name='genderId' isInvalid={!!errors.genderId} {...register("genderId", AddValidation.required)} />
                                                )
                                            })
                                    }
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.genderId?.type === "required" && "Gender field is required"}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button size='sm' variant="primary" type="submit" className="mx-2">Add User</Button>
                        <Button size='sm' variant="danger" type="button" className="mx-2" onClick={() => users('/usermanagement/users')}>Cancel</Button>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}

export default AddUser
