import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Error404 from 'theme-components/errors/Error404';
import Error500 from 'theme-components/errors/Error500';
import Dashboard from 'theme-components/dashboards/default';
import Login from '../pages/authentication/login';
import AddUser from 'pages/user-management/add';
import UserList from 'pages/user-management/list';
import EditUser from 'pages/user-management/edit';
import UserProfile from 'app-components/user-management/profile';
import EditProfile from 'app-components/user-management/edit-profile';
import ForgetPassword from 'theme-components/authentication/simple/ForgetPassword';
import PropertyTypes from 'app-components/property-components/property-types';
import Clientuser from 'app-components/property-components/client-user';
import ClientList from 'app-components/property-components/client-lists';
import ClientApproved from 'app-components/property-components/client-approved';
import PreviewProperty from 'app-components/property-components/preview-property';
import Home from 'pages/settings/home';
import PropertySearch from 'pages/settings/property-search';
import PropertyDetails from 'pages/settings/property-details';
import Header from 'pages/settings/header';
import AddProperty from 'pages/settings/add-property';
import Profile from 'pages/settings/profile';
import ProfileDashboard from 'pages/settings/profile-dashboard';
import MyListing from 'pages/settings/my-listing';
import DocumentKyc from 'pages/settings/document-kyc';
import MyTenant from 'pages/settings/my-tenant';
import MyTenanty from 'pages/settings/my-tenanty';
import AboutUs from 'pages/settings/about-us';
import TermsAndCondition from 'pages/settings/terms-and-condition';
import PrivacyPolicy from 'pages/settings/privacy-policy';
import FAQS from 'pages/settings/faqs';
import Contact from 'pages/settings/contact';
import Career from 'pages/settings/career';
import CareerDetails from 'pages/settings/career-details';
import Blog from 'pages/settings/blog';
import BlogDetails from 'pages/settings/blog-details';
import ReferAndEran from 'pages/settings/refer-and-earn';

const FalconRoutes = () => {
  return (
    <Routes>

      <Route element={<ErrorLayout />}>
        <Route path="*" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      {/*- ------------- Authentication ---------------------------  */}

      <Route element={<AuthSimpleLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
      </Route>

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route path="/" element={<Dashboard />} />
        <Route path="/usermanagement/adduser" element={<AddUser />} />
        <Route path="/usermanagement/users" element={<UserList />} />
        <Route path="/usermanagement/edituser/:editId" element={<EditUser />} />
        <Route path="/usermanagement/profile" element={<UserProfile />} />
        <Route path="/usermanagement/editprofile" element={<EditProfile />} />

        <Route path='/Components/propertytypes' element={<PropertyTypes />} />
        <Route path='/Components/clientuser' element={<Clientuser />} />
        <Route path='/Components/clientlist' element={<ClientList />} />
        <Route path='/Components/clientapproved' element={<ClientApproved />} />
        <Route path='/Components/previewproperty' element={<PreviewProperty />} />
        <Route path='/settings/header' element={<Header />} />
        <Route path='/settings/home' element={<Home />} />
        <Route path='/settings/property-search' element={<PropertySearch />} />
        <Route path='/settings/property-details' element={<PropertyDetails />} />
        <Route path='/settings/add-property' element={<AddProperty />} />
        <Route path='/settings/profile' element={<Profile />} />
        <Route path='/settings/dashboard' element={<ProfileDashboard />} />
        <Route path='/settings/liting' element={<MyListing />} />
        <Route path='/settings/document-kyc' element={<DocumentKyc />} />
        <Route path='/settings/my-tenant' element={<MyTenant />} />
        <Route path='/settings/my-tenanty' element={<MyTenanty />} />
        <Route path='/settings/about-us' element={<AboutUs />} />
        <Route path='/settings/terms&condition' element={<TermsAndCondition />} />
        <Route path='/settings/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/settings/faqs' element={<FAQS />} />
        <Route path='/settings/contact' element={<Contact />} />
        <Route path='/settings/career' element={<Career />} />
        <Route path='/settings/career-details' element={<CareerDetails />} />
        <Route path='/settings/blog' element={<Blog />} />
        <Route path='/settings/blog-details' element={<BlogDetails />} />
        <Route path='/settings/refer-and-earn' element={<ReferAndEran />} />
      </Route>
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
