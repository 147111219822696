import React from 'react';
import { Col, Row, Form, Button, Image, Accordion, Card, useAccordionButton } from "react-bootstrap";
import appTheme from "../../app-theme.json";
import { FaEdit, FaCheck } from "react-icons/fa";

const FAQS = () => {

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button size="sm" className="admin_btn"
                variant='falcon-primary'
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    const { FAQsBannerContents, FAQsSection } = appTheme.FAQsPage;

    return (
        <div className="main_border">
            <Accordion defaultActiveKey="0" className="accordion_box">
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">banner details</h5>
                        </div>
                        <CustomToggle eventKey="1"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={FAQsBannerContents.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>subtitle</Form.Label>
                                            <Form.Control type="text" value={FAQsBannerContents.subTitle.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Image</Form.Label>
                                            <Form.Label className="image_label_box ms-4">
                                                <Image src={FAQsBannerContents.image} width="100px" />
                                                <Form.Control type="file" />
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">FAQS</h5>
                        </div>
                        <CustomToggle eventKey="2"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={FAQsSection.mainTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>subtitle</Form.Label>
                                            <Form.Control type="text" value={FAQsSection.mainSubTitile.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">FAQS Forms</h5>
                        </div>
                        <CustomToggle eventKey="3"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={FAQsSection.FAQsForms.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>button</Form.Label>
                                            <Form.Control type="text" value={FAQsSection.FAQsForms.button} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default FAQS
