import AppSetting from '../../appConfig.json';
import axios from 'axios';
import { httpHeadersWithToken } from '../../helpers/utils';
import { exceptionHandler } from 'services/validation/api-handler';

export const getGenders = async () => {

    try {

        const url = AppSetting.SERVER_URL + "/Lookups/Genders";
        const res = await axios.get(url, httpHeadersWithToken);
        return res.data;

    } catch (err) {

        return exceptionHandler(err)
    }
}

export const getRoles = async () => {

    try {

        const url = AppSetting.SERVER_URL + "/Lookups/Roles";
        const res = await axios.get(url, httpHeadersWithToken);
        return res.data;

    } catch (err) {

        return exceptionHandler(err)

    }
}