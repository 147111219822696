export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/',
    }
  ]
};

export const usermanagement = {
  label: 'User Management',
  labelDisable: true,
  children: [
    {
      name: 'User Management',
      icon: 'user',
      active: true,
      children: [
        {
          name: 'Add User',
          to: '/usermanagement/adduser',
          active: true
        },
        {
          name: 'Users',
          to: '/usermanagement/users',
          active: true
        }
      ]
    },
  ]
};

export const propertyComponents = {
  label: ' Components ',
  labelDisable: true,
  children: [
    {
      name: ' Components ',
      icon: 'puzzle-piece',
      active: true,
      children: [
        {
          name: 'Property Types',
          to: '/Components/propertytypes',
          active: true
        },
        {
          name: 'Client User',
          to: '/Components/clientuser',
          active: true
        },
        {
          name: 'Client List',
          to: '/Components/clientlist',
          active: true
        },
        {
          name: 'Client Approved',
          to: '/Components/clientapproved',
          active: true
        },
        {
          name: 'Preview Property',
          to: '/Components/previewproperty',
          active: true
        },

      ]
    },
  ]
};

export const settings = {
  label: 'Settings',
  labelDisable: true,
  children: [
    {
      name: 'Settings',
      icon: 'wrench',
      active: true,
      children: [
        {
          name: 'Common',
          icon: 'table',
          active: true,
          children: [
            {
              name: 'Header',
              to: '/settings/header',
              active: true
            },
            {
              name: 'Home',
              to: '/settings/home',
              active: true
            },
          ]
        },
        {
          name: 'Profile',
          icon: 'user',
          active: true,
          children: [
            {
              name: 'Profile',
              to: '/settings/profile',
              active: true
            },
            {
              name: 'Dashboard',
              to: '/settings/dashboard',
              active: true
            },
            {
              name: 'My Listing',
              to: '/settings/liting',
              active: true
            },
            {
              name: 'Document KYC',
              to: '/settings/document-kyc',
              active: true
            },
            {
              name: 'My Tenant',
              to: '/settings/my-tenant',
              active: true
            },
            {
              name: 'My Tenanty',
              to: '/settings/my-tenanty',
              active: true
            },
          ]
        },
        {
          name: 'Property',
          icon: 'shapes',
          active: true,
          children: [
            {
              name: 'Property Search',
              to: '/settings/property-search',
              active: true
            },
            {
              name: 'Property Details',
              to: '/settings/property-details',
              active: true
            },
            {
              name: 'Add Aroperty',
              to: '/settings/add-property',
              active: true
            },
          ]
        },
        {
          name: 'Others',
          icon: 'tags',
          active: true,
          children: [
            {
              name: 'About us',
              to: '/settings/about-us',
              active: true
            },
            {
              name: 'Terms & Condition',
              to: '/settings/terms&condition',
              active: true
            },
            {
              name: 'Privacy Policy',
              to: '/settings/privacy-policy',
              active: true
            },
            {
              name: 'Faqs',
              to: '/settings/faqs',
              active: true
            },
            {
              name: 'Contact',
              to: '/settings/contact',
              active: true
            },
            {
              name: 'Career',
              to: '/settings/career',
              active: true
            },
            {
              name: 'Career Details',
              to: '/settings/career-details',
              active: true
            },
            {
              name: 'Blog',
              to: '/settings/blog',
              active: true
            },
            {
              name: 'Blog Details',
              to: '/settings/blog-details',
              active: true
            },
            {
              name: 'Refer and Earn',
              to: '/settings/refer-and-earn',
              active: true
            }
          ]
        },
      ]
    },
  ]
};

export default [
  dashboardRoutes,
  usermanagement,
  propertyComponents,
  settings
];
