import { useEffect, useState } from "react";
import { Button, Toast } from "react-bootstrap";
import "../assets/scss/custom_theme/custom_toast.scss";
import { useNavigate } from "react-router-dom";

const RedirectModal = ({ show }) => {
    const [counter, setCounter] = useState(10);
    const login = useNavigate();

    useEffect(() => {
        if (show) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter, show]);

    return (
        <>
            <div className="custom_toast">
                <Toast onClose={() => show} show={show} delay={10000} autohide>
                    <Toast.Body>
                        <p>your session has been expired. <br /> Redirecting you to login <b>({counter})</b></p>
                        <div className="text-end">
                            <Button size="sm" onClick={() => login("/login")}>Login</Button>
                        </div>
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
}

export default RedirectModal;