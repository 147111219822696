
import { toast } from 'react-toastify';
import { tostStyle } from '../../helpers/utils';
import { Messages } from '../../helpers/messages'
import jwtDecode from 'jwt-decode';

export const exceptionHandler = (err) => {

    if (err.response) {
        if (err.response.status == 401 || err.response.status == 403)
            toast.error(Messages.TokenError, tostStyle);

        else if (err.response.status == 404) toast.error(Messages.NotFound, tostStyle);

        else toast.error(Messages.ServerError, tostStyle);
    }

    else if (err.request) { toast.error(Messages.Failed, tostStyle); }

    else { toast.error(err.message, tostStyle); }
}

export const tokenHandler = () => {
    if (localStorage.getItem('token')) {
        const decodeToken = jwtDecode(localStorage.getItem('token'));
        const currentDate = new Date();
        if (decodeToken.exp * 1000 < currentDate.getTime()) {
            localStorage.removeItem('token');
            return true;
        }
        return false;
    }
}
