import React from 'react';
import { Col, Row, Form, Button, Image, Accordion, Card, useAccordionButton } from "react-bootstrap";
import appTheme from "../../app-theme.json";
import { FaEdit, FaCheck, FaBan } from "react-icons/fa";


const AddProperty = () => {

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button size="sm" className="admin_btn"
                variant='falcon-primary'
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    const { propertyLayout, localityDetails, propertyDetails, rentalDetails, amenitiesDetails, galleryDetails, publishStatusPage } = appTheme;

    return (
        <div className="main_border">
            <Accordion defaultActiveKey="0" className="accordion_box">
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">add Property navigation</h5>
                        </div>
                        <CustomToggle eventKey="1"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>locality details</Form.Label>
                                            <Form.Control type="text" value={propertyLayout.localityDetails} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property details</Form.Label>
                                            <Form.Control type="text" value={propertyLayout.propertyDetails} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>rental Details</Form.Label>
                                            <Form.Control type="text" value={propertyLayout.rentalDetails} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>amenities details</Form.Label>
                                            <Form.Control type="text" value={propertyLayout.amenities} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>gallery</Form.Label>
                                            <Form.Control type="text" value={propertyLayout.gallery} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>publish</Form.Label>
                                            <Form.Control type="text" value={propertyLayout.publish} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">locality Details label</h5>
                        </div>
                        <CustomToggle eventKey="2"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>locality details title</Form.Label>
                                            <Form.Control type="text" value={localityDetails.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>city</Form.Label>
                                            <Form.Control type="text" value={localityDetails.city} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Sector</Form.Label>
                                            <Form.Control type="text" value={localityDetails.propertySector} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Type</Form.Label>
                                            <Form.Control type="text" value={localityDetails.propertyType} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>landmark Street</Form.Label>
                                            <Form.Control type="text" value={localityDetails.landmarkStreet} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>locality</Form.Label>
                                            <Form.Control type="text" value={localityDetails.locality} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>map Title</Form.Label>
                                            <Form.Control type="text" value={localityDetails.mapTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>map subtitle</Form.Label>
                                            <Form.Control type="text" value={localityDetails.mapParagraph.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>map latitude</Form.Label>
                                            <Form.Control type="text" value={localityDetails.latitude} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>map langitude</Form.Label>
                                            <Form.Control type="text" value={localityDetails.langitude} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>submit Button</Form.Label>
                                            <Form.Control type="text" value={localityDetails.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>next Button</Form.Label>
                                            <Form.Control type="text" value={localityDetails.nextButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">property Details label</h5>
                        </div>
                        <CustomToggle eventKey="3"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property details title</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Name</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.propertyName} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>appartment Type</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.appartmentType} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>bhk Type</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.bhkType} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>floor</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.floor} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>total Floor</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.totalFloor} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Age</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.propertyAge} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>facing</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.facing} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>built Up Area</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.builtUpArea} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>submit Button</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>next Button</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.nextButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">rental Details label</h5>
                        </div>
                        <CustomToggle eventKey="4"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>rental Details title</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>expected Rent</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.expectedRent} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>expected Deposit</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.expectedDeposit} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>monthly Maintenance</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.monthlyMaintenance} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>maintenance Amount</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.maintenanceAmount} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>available From</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.availableFrom} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>preferred Tenants</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.preferredTenants} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>furnishing</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.furnishing} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>parking</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.parking} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>discription</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.discription} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>submit Button</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>next Button</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.nextButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">amenities Details label</h5>
                        </div>
                        <CustomToggle eventKey="5"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>amenities Details title</Form.Label>
                                            <Form.Control type="text" value={amenitiesDetails.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>bathroom</Form.Label>
                                            <Form.Control type="text" value={amenitiesDetails.bathroom} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>balcony</Form.Label>
                                            <Form.Control type="text" value={amenitiesDetails.balcony} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Shown</Form.Label>
                                            <Form.Control type="text" value={amenitiesDetails.propertyShown} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>water Spply</Form.Label>
                                            <Form.Control type="text" value={amenitiesDetails.waterSpply} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>add Direction</Form.Label>
                                            <Form.Control type="text" value={amenitiesDetails.addDirection} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>available Amenities</Form.Label>
                                            <Form.Control type="text" value={amenitiesDetails.availableAmenities} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>submit Button</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>next Button</Form.Label>
                                            <Form.Control type="text" value={rentalDetails.nextButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">gallery Details label</h5>
                        </div>
                        <CustomToggle eventKey="6"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="6">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>gallery Details title</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>change Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.changeButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>delete Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.deleteButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>add Photos Title</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.addPhotosTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>add Photos Paragraph</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.addPhotosParagraph} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>add Photos Sizes</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.addPhotosSizes} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>add Photos Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.addPhotosButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>change Modal Title</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.changeModalTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>change Modal image Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.changeModalButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>change submit Modal Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.submitModalButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>delete Modal Title</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.deleteModalTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>delete Modal Paragraph</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.deleteModalParagraph} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>delete Modal Cancel Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.deleteModalCancelButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>delete Modal Confirm Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.deleteModalConfirmButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>submit Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>next Button</Form.Label>
                                            <Form.Control type="text" value={galleryDetails.nextButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">status publish label</h5>
                        </div>
                        <CustomToggle eventKey="7"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="7">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>keep as publish</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.publishedStatus.keepPublish} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>draft</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.publishedStatus.draft} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>inactive</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.publishedStatus.inactive} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">status draft label</h5>
                        </div>
                        <CustomToggle eventKey="8"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="8">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>keep as Draft</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.draftStatus.keepDraft} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>publish</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.draftStatus.publish} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>inactive</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.draftStatus.inactive} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">status inactive label</h5>
                        </div>
                        <CustomToggle eventKey="9"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="9">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>keep as Inactive</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.inactiveStatus.keepInactive} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>publish</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.inactiveStatus.publish} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>draft</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.inactiveStatus.draft} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">status confirmation Model label</h5>
                        </div>
                        <CustomToggle eventKey="10"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="10">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>confirmation Model message</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.confirmationModel.message} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>confirmation cancel Button</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.confirmationModel.cancelButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>confirmation ok Button</Form.Label>
                                            <Form.Control type="text" value={publishStatusPage.confirmationModel.okButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default AddProperty
