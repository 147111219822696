import React, { useState } from 'react';
import { Col, Row, Form, Button, Image, Accordion, Card, useAccordionButton } from "react-bootstrap";
import appTheme from "../../app-theme.json";
import { FaEdit, FaCheck, FaBan } from "react-icons/fa";

const MyTenant = () => {

    const [titlePage, setTitlePage] = useState(false);

    const editTitle = () => { setTitlePage(true) }
    const cancelTitle = () => { setTitlePage(false) }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button size="sm" className="admin_btn"
                variant='falcon-primary'
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    const { myTenantPage } = appTheme;

    return (
        <div className="main_border">
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">Tenant Page title</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            titlePage ?
                                <div className="update_value">
                                    <Form.Control type='text' value={myTenantPage.title} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelTitle}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{myTenantPage.title}</p>
                                    <Button size="sm" className="admin_btn" onClick={editTitle}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Accordion defaultActiveKey="0" className="accordion_box">
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">Tenants label</h5>
                        </div>
                        <CustomToggle eventKey="1"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>tenant</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenant} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>start Date</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.startDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>end Date</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.endDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>rent Price</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.rentPrice} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>button</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.button} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">tenant Details</h5>
                        </div>
                        <CustomToggle eventKey="2"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>tenant Details title</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>remove Button</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.removeButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">user Details</h5>
                        </div>
                        <CustomToggle eventKey="3"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>start Date</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.userDetails.startDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>member</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.userDetails.member} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>rent Price</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.userDetails.rentPrice} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>deposite Price</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.userDetails.depositePrice} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">personal Details</h5>
                        </div>
                        <CustomToggle eventKey="4"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.personalDetails.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>mobile No</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.personalDetails.mobileNo} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>house No</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.personalDetails.houseNo} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>area</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.personalDetails.area} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>locality</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.personalDetails.locality} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>state</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.personalDetails.state} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>pin Code</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.personalDetails.pinCode} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">agreement Details</h5>
                        </div>
                        <CustomToggle eventKey="5"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.agreement.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>rent Agreement</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.agreement.rentAgreement} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">document Details</h5>
                        </div>
                        <CustomToggle eventKey="6"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="6">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.documentKyc.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>adhar Card</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.documentKyc.adharCard} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>pan Card</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.documentKyc.panCard} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>voter Id</Form.Label>
                                            <Form.Control type="text" value={myTenantPage.tenantDetails.documentKyc.voterId} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default MyTenant
