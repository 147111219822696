import React, { useState } from 'react';
import { Form, Row, Col, Button, Image, Card, Accordion, useAccordionButton } from "react-bootstrap";
import { FaCheck, FaBan, FaEdit } from "react-icons/fa";
import appTheme from "../../app-theme.json";

const PropertySearch = () => {

    const [updateFilter, setUpdateFilter] = useState(false);
    const [updateClearBtn, setUpdateClearBtn] = useState(false);
    const [updateApplyBtn, setUpdateApplyBtn] = useState(false);

    const cancelFilter = () => { setUpdateFilter(false) }
    const editFilter = () => { setUpdateFilter(true) }
    const editClearBtn = () => { setUpdateClearBtn(true) }
    const cancelClearBtn = () => { setUpdateClearBtn(false) }
    const editApplyBtn = () => { setUpdateApplyBtn(true) }
    const cancelApplyBtn = () => { setUpdateApplyBtn(false) }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button size="sm" className="admin_btn"
                variant='falcon-primary'
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    const { filter, propertySearch } = appTheme;

    return (

        <div className="main_border">
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">filter</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateFilter ?
                                <div className="update_value">
                                    <Form.Control type="text" value={filter.title} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelFilter}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{filter.title}</p>
                                    <Button size="sm" className="admin_btn" onClick={editFilter}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">filter clear button</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateClearBtn ?
                                <div className="update_value">
                                    <Form.Control type="text" value={filter.clear} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelClearBtn}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{filter.clear}</p>
                                    <Button size="sm" className="admin_btn" onClick={editClearBtn}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">filter apply button</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateApplyBtn ?
                                <div className="update_value">
                                    <Form.Control type="text" value={filter.button} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelApplyBtn}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{filter.button}</p>
                                    <Button size="sm" className="admin_btn" onClick={editApplyBtn}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Accordion defaultActiveKey="0" className="accordion_box">
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">Property filters label</h5>
                        </div>
                        <CustomToggle eventKey="1"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>city</Form.Label>
                                            <Form.Control type="text" value={filter.citiyTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>location</Form.Label>
                                            <Form.Control type="text" value={filter.location.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>bhk types</Form.Label>
                                            <Form.Control type="text" value={filter.bhkTypeTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Apartment Types</Form.Label>
                                            <Form.Control type="text" value={filter.apartmentTypesTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>preferred Tenants</Form.Label>
                                            <Form.Control type="text" value={filter.preferredTenantsTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>furnishing Type</Form.Label>
                                            <Form.Control type="text" value={filter.furnishingTypeTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>parking types</Form.Label>
                                            <Form.Control type="text" value={filter.parkingTypesTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Ages</Form.Label>
                                            <Form.Control type="text" value={filter.propertyAgesTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">Property price range</h5>
                        </div>
                        <CustomToggle eventKey="2"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>price range title</Form.Label>
                                            <Form.Control type="text" value={filter.priceRange.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>min Price</Form.Label>
                                            <Form.Control type="text" value={filter.priceRange.minPrice} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>max Price</Form.Label>
                                            <Form.Control type="text" value={filter.priceRange.maxPrice} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>max Limit</Form.Label>
                                            <Form.Control type="text" value={filter.priceRange.maxLimit} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">Property built up area</h5>
                        </div>
                        <CustomToggle eventKey="3"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>size title</Form.Label>
                                            <Form.Control type="text" value={filter.sizeRange.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>min size</Form.Label>
                                            <Form.Control type="text" value={filter.sizeRange.minPrice} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>max size</Form.Label>
                                            <Form.Control type="text" value={filter.sizeRange.maxPrice} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>max Limit</Form.Label>
                                            <Form.Control type="text" value={filter.sizeRange.maxLimit} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">Property search label</h5>
                        </div>
                        <CustomToggle eventKey="4"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Property search label</Form.Label>
                                            <Form.Control type="text" value={propertySearch.results} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Property search results</Form.Label>
                                            <Form.Control type="text" value={propertySearch.label} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">no results found</h5>
                        </div>
                        <CustomToggle eventKey="5"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={propertySearch.notResult.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>subtitle</Form.Label>
                                            <Form.Control type="text" value={propertySearch.notResult.paragraph} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>image</Form.Label>
                                            <Form.Label className="image_label_box ms-4">
                                                <Image src={propertySearch.notResult.image} width="100px" />
                                                <Form.Control type="file" />
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default PropertySearch
