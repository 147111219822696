import React from 'react';
import { useForm } from "react-hook-form";
import { Delete, Lock, Restore, Unlock } from "services/apis/user-account-service";
import { ConfirmModalValidation } from "services/validation/form-validation";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";
import FalconCloseButton from "theme-components/common/FalconCloseButton";
import { modelAction, tostStyle } from "helpers/utils";
import { UserListModal, AddUserType } from 'helpers/messages';

const ConfirmModal = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onConfirmation = async (data) => {
        let response = null;
        const action = props.options.action;
        const payload = { userId: props.options.userId, reason: data.reason };

        switch (action) {
            case modelAction.DELETE: { response = await Delete(payload); break; }
            case modelAction.RESTORE: { response = await Restore(payload); break; }
            case modelAction.LOCK: { response = await Lock(payload); break; }
            case modelAction.UNLOCK: { response = await Unlock(payload); break; }
        }

        if (response != null || response != undefined) {
            if (response.isSuccess == true) toast.success(response.successMessage, tostStyle);
            else toast.warning(response.errorMessages[0].message, tostStyle);
        }

        props.onClose(true);
    }

    let reasonError;
    if (errors?.reason?.type === AddUserType.required) { reasonError = UserListModal.required }
    if (errors?.reason?.type === AddUserType.minLength) { reasonError = UserListModal.minLength }

    return <>
        <Modal show={props.options.show} backdrop="static" keyboard={false} className="fs--1">
            <Form onSubmit={handleSubmit(onConfirmation)}>
                <Modal.Header>
                    <Modal.Title className="fs-0">Confirmation Alert!</Modal.Title>
                    <FalconCloseButton onClick={() => props.onClose(false)} />
                </Modal.Header>
                <Modal.Body>
                    <p>{props.options.message}</p>
                    <Form.Group  >
                        <Form.Label className="fs--1">Reason</Form.Label>
                        <Form.Control type="text" size="sm" placeholder="Please enter reason" isInvalid={!!errors.reason} name="reason" {...register("reason", ConfirmModalValidation.reason)} />
                        <Form.Control.Feedback type="invalid">
                            {reasonError}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size="sm" onClick={() => props.onClose(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" size="sm" type="submit">Yes</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default ConfirmModal;