import React from 'react';
import { Col, Row, Form, Button, Image, Accordion, Card, useAccordionButton } from "react-bootstrap";
import appTheme from "../../app-theme.json";
import { FaEdit, FaCheck } from "react-icons/fa";

const ReferAndEran = () => {

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button size="sm" className="admin_btn"
                variant='falcon-primary'
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    const { referAndEarnBannerContents, referAndEarnSection } = appTheme.referAndEarnPage;

    return (
        <div className="main_border">
            <Accordion defaultActiveKey="0" className="accordion_box">
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">banner details</h5>
                        </div>
                        <CustomToggle eventKey="1"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={referAndEarnBannerContents.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>subtitle</Form.Label>
                                            <Form.Control type="text" value={referAndEarnBannerContents.subTitle.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Image</Form.Label>
                                            <Form.Label className="image_label_box ms-4">
                                                <Image src={referAndEarnBannerContents.image} width="100px" />
                                                <Form.Control type="file" />
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">refer And Earn Section</h5>
                        </div>
                        <CustomToggle eventKey="2"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.mainTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>subtitle</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.mainSubTitile.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">Owner details section</h5>
                        </div>
                        <CustomToggle eventKey="3"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Image</Form.Label>
                                            <Form.Label className="image_label_box ms-4">
                                                <Image src={referAndEarnSection.ownerDetails.image} width="100px" />
                                                <Form.Control type="file" />
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>subtitle</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.subTitle.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>button</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.button} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal title</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.modalDetail.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal cancel button</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.modalDetail.cancelButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal submit button</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.modalDetail.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal city</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.modalDetail.city.label} />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal owner Name</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.modalDetail.ownerName.label} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal mobile Number</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.modalDetail.mobileNumber.label} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal message</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.ownerDetails.modalDetail.message.label} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">upload Photos section</h5>
                        </div>
                        <CustomToggle eventKey="4"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Image</Form.Label>
                                            <Form.Label className="image_label_box ms-4">
                                                <Image src={referAndEarnSection.uploadPhotos.image} width="100px" />
                                                <Form.Control type="file" />
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>title</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.uploadPhotos.title} />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>button</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.uploadPhotos.button} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal title</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.uploadPhotos.modal.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal cancel button</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.uploadPhotos.modal.cancelButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal submit button</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.uploadPhotos.modal.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal city</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.uploadPhotos.modal.city.label} />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal upload Photos</Form.Label>
                                            <Form.Control type="text" value={referAndEarnSection.uploadPhotos.modal.photos.label} />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default ReferAndEran 
