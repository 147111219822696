import React, { useState } from 'react';
import { Col, Row, Form, Button } from "react-bootstrap";
import appTheme from "../../app-theme.json";
import { FaEdit, FaCheck, FaBan } from "react-icons/fa";

const ProfileDashboard = () => {

    const [updateTitle, setUpdateTitle] = useState(false);

    const editTitle = () => { setUpdateTitle(true) }
    const cancelTitle = () => { setUpdateTitle(false) }

    const { dashboardPage } = appTheme;

    return (
        <div className="main_border">
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">page title</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateTitle ?
                                <div className="update_value">
                                    <Form.Control type='text' value={dashboardPage.title} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelTitle}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{dashboardPage.title}</p>
                                    <Button size="sm" className="admin_btn" onClick={editTitle}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ProfileDashboard
