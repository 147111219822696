import axios from 'axios';
import AppSetting from '../../appConfig.json';
import { httpHeadersWithToken } from '../../helpers/utils';
import { exceptionHandler } from '../validation/api-handler';

export const GetAll = async (payLoad) => {
    try {

        //Todo: Call start 
        const url = AppSetting.SERVER_URL + "/UserAccount/GetAll";
        const res = await axios.post(url, payLoad, httpHeadersWithToken);

        //Todo: Call  stop loader here
        return res.data;

    }
    catch (err) {
        //Todo: Call  stop loader here
        return exceptionHandler(err);
    }
}


export const Lock = async (payLoad) => {
    try {
        const url = AppSetting.SERVER_URL + "/UserAccount/Lock";
        const res = await axios.post(url, payLoad, httpHeadersWithToken);

        return res.data;
    }
    catch (err) { return exceptionHandler(err); }
}

export const Unlock = async (payLoad) => {
    try {
        const url = AppSetting.SERVER_URL + "/UserAccount/Unlock";
        const res = await axios.post(url, payLoad, httpHeadersWithToken);

        return res.data;
    }
    catch (err) { return exceptionHandler(err); }
}

export const Delete = async (payLoad) => {
    try {
        const url = AppSetting.SERVER_URL + "/UserAccount/Delete";
        const res = await axios.post(url, payLoad, httpHeadersWithToken);

        return res.data;
    }
    catch (err) { return exceptionHandler(err); }
}


export const Restore = async (payLoad) => {
    try {
        const url = AppSetting.SERVER_URL + "/UserAccount/Restore";
        const res = await axios.post(url, payLoad, httpHeadersWithToken);

        return res.data;
    }
    catch (err) { return exceptionHandler(err); }
}
