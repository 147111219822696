import React from 'react'
import { Card, Form, Button } from 'react-bootstrap'
import AdvanceTableExample from './advance-table'

const Clientuser = () => {
    return (
        <>

            <Card>
                <Card.Body>
                    <Form>
                        <div className="d-flex align-items-center justify-content-between">
                            <Form.Group className='flex-1'>
                                <Form.Label className='fs--1'>Search Clients</Form.Label>
                                <Form.Control type="text" placeholder="Search Clients" size='sm' />
                            </Form.Group>
                            <Form.Group className='ms-3 flex-1'>
                                <Form.Label className='fs--1'>Filter</Form.Label>
                                <Form.Select size="sm">
                                    <option value="" hidden>Filter</option>
                                    <option value="All" >All</option>
                                    <option value="Owner" >Owner</option>
                                    <option value="Tenants" >Tenants</option>
                                    <option value="Owner/Tenants" >Owner/Tenants</option>
                                </Form.Select>
                            </Form.Group>
                            <Button variant='success' size='sm' className='inline-block mt-4 ms-3'>Add</Button>
                        </div>
                    </Form>
                    <div className="mt-3">
                        <AdvanceTableExample />
                    </div>
                </Card.Body>
            </Card>

        </>
    )
}

export default Clientuser
