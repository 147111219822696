import React, { useState } from 'react';
import { Form, Row, Col, Image, Button } from "react-bootstrap";
import { FaEdit, FaCheck, FaBan } from "react-icons/fa";
import appTheme from "../../app-theme.json";


const Header = () => {

    const [updateBrand, setUpdateBrand] = useState({ logo: false, name: false });
    const [updateButtons, setUpdateButtons] = useState({ login: false, postProperty: false });

    const editBLHandler = () => { setUpdateBrand({ ...updateBrand, logo: true }) }
    const cancelBLHandler = () => { setUpdateBrand({ ...updateBrand, logo: false }) }
    const editBNHandler = () => { setUpdateBrand({ ...updateBrand, name: true }) }
    const cancelBNHandler = () => { setUpdateBrand({ ...updateBrand, name: false }) }
    const editLoginHandler = () => { setUpdateButtons({ ...updateButtons, login: true }) }
    const cancelLoginHandler = () => { setUpdateButtons({ ...updateButtons, login: false }) }
    const editPostBtnHandler = () => { setUpdateButtons({ ...updateButtons, postProperty: true }) }
    const cancelPostBtnHandler = () => { setUpdateButtons({ ...updateButtons, postProperty: false }) }

    const { brand, login, postProperty } = appTheme;

    return (
        <div className="main_border">
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">Brand Logo</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateBrand.logo ?
                                <div className="update_value">
                                    <Form.Label className="image_label_box">
                                        <Image src={brand.brandLogo} />
                                        <Form.Control type="file" className="logo_input" />
                                        <small className="ms-2">logo should be 204 X 38 pixels</small>
                                    </Form.Label>
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelBLHandler}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <Image src={brand.brandLogo} />
                                    <Button size="sm" className="admin_btn" onClick={editBLHandler}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">Brand Name</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateBrand.name ?
                                <div className="update_value">
                                    <Form.Control type='text' value={brand.brandName} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelBNHandler}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{brand.brandName}</p>
                                    <Button size="sm" className="admin_btn" onClick={editBNHandler}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">Login Button</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateButtons.login ?
                                <div className="update_value">
                                    <Form.Control type='text' value={login.title} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelLoginHandler}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{login.title}</p>
                                    <Button size="sm" className="admin_btn" onClick={editLoginHandler}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">Post Property Button</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateButtons.postProperty ?
                                <div className="update_value">
                                    <Form.Control type='text' value={postProperty.title} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelPostBtnHandler}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{postProperty.title}</p>
                                    <Button size="sm" className="admin_btn" onClick={editPostBtnHandler}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default Header
