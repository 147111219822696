import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, Image } from 'react-bootstrap';
import profile from '../../assets/img/profile.webp';
import './profile.css'
import { useNavigate } from 'react-router-dom';
import { GetUserById } from 'services/apis/user-service';

const UserProfile = () => {
    const [userData, setUserData] = useState();
    const editProfileNavigate = useNavigate();

    const getData = async () => {
        const responseById = await GetUserById();
        if (responseById != null) {
            setUserData(responseById.data);
        }
    }

    useEffect(() => {
        getData();
    }, [])
    return (
        <>
            {
                userData &&
                <Card>
                    <Card.Header className="d-flex justify-content-between align-items-center py-2">
                        <h5>User Profile</h5>
                        <Button variant='primary' onClick={() => editProfileNavigate('/usermanagement/editprofile')}>Edit Profile</Button>
                    </Card.Header>
                    <Card.Body className="bg-light">
                        <Row>
                            <Col md={4} className='mb-3 mb-md-0'>
                                <div className="user_img">
                                    <Image src={profile} rounded width={100} height={100} />
                                    <h5>{userData.firstName} {userData.lastName}</h5>
                                    <p>{userData.role[0].roleName}</p>
                                </div>
                            </Col>
                            <Col md={8}>
                                <div className="user_details">
                                    <Row className='border-bottom '>
                                        <Col xs={4}>
                                            <p className='title'>Full Name</p>
                                        </Col>
                                        <Col xs={8}>
                                            <p className='title_info'>{userData.firstName} {userData.lastName}</p>
                                        </Col>
                                    </Row>
                                    <Row className='border-bottom '>
                                        <Col xs={4}>
                                            <p className='title'>Email</p>
                                        </Col>
                                        <Col xs={8}>
                                            <p className='title_info'>{userData.email}</p>
                                        </Col>
                                    </Row>
                                    <Row className='border-bottom '>
                                        <Col xs={4}>
                                            <p className='title'>Mobile</p>
                                        </Col>
                                        <Col xs={8}>
                                            <p className='title_info'>{userData.mobileNumber ? userData.mobileNumber : 1234567890}</p>
                                        </Col>
                                    </Row>
                                    <Row className='border-bottom '>
                                        <Col xs={4}>
                                            <p className='title'>Address</p>
                                        </Col>
                                        <Col xs={8}>
                                            <p className='title_info'>Laxmi Nagar , New Delhi</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
        </>


    )
}

export default UserProfile
