import React, { useState } from 'react';
import "../../assets/scss/settings/home.scss";
import { Col, Row, Form, Button, Image, Accordion, Card, useAccordionButton } from "react-bootstrap";
import appTheme from "../../app-theme.json";
import { FaEdit, FaCheck, FaBan } from "react-icons/fa";

const Home = () => {

    const [updateBannerText, setUpdateBannerText] = useState(false);

    const editBannerText = () => { setUpdateBannerText(true) }
    const cancelBannerText = () => { setUpdateBannerText(false) }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button size="sm" className="admin_btn"
                variant='falcon-primary'
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    const { heroSectionTitle, propertyTypes, aboutSection, regionalPropertyHomepage, propertyCounter, metroCities } = appTheme;

    return (
        <div className="home_setting">

            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">Banner Text</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateBannerText ?
                                <div className="update_value">
                                    <Form.Control type='text' value={heroSectionTitle} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelBannerText}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{heroSectionTitle}</p>
                                    <Button size="sm" className="admin_btn" onClick={editBannerText}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>

            <Accordion defaultActiveKey="0" className="accordion_box">
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">Property Category Title</h5>
                        </div>
                        <CustomToggle eventKey="1"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Property Category maintitle</Form.Label>
                                            <Form.Control type="text" value={propertyTypes.mainTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Property Category subtitle</Form.Label>
                                            <Form.Control type="text" value={propertyTypes.mainSubTitile.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Property Category label</Form.Label>
                                            <Form.Control type="text" value={propertyTypes.propertiesValue} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">About us</h5>
                        </div>
                        <CustomToggle eventKey="2"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>About maintitle</Form.Label>
                                            <Form.Control type="text" value={aboutSection.mainTitle.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>about subtitle</Form.Label>
                                            <Form.Control type="text" value={aboutSection.mainSubTitile.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>about title</Form.Label>
                                            <Form.Control type="text" value={aboutSection.title.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>about paragraph</Form.Label>
                                            <Form.Control type="text" value={aboutSection.paragraph.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>about button</Form.Label>
                                            <Form.Control type="text" value={aboutSection.button.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>about Image</Form.Label>
                                            <Form.Label className="image_label_box ms-4">
                                                <Image src={aboutSection.image} width="100px" />
                                                <Form.Control type="file" />
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col >
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">regional Property</h5>
                        </div>
                        <CustomToggle eventKey="3"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>regional Property maintitle</Form.Label>
                                            <Form.Control type="text" value={regionalPropertyHomepage.mainTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>regional Property subtitle</Form.Label>
                                            <Form.Control type="text" value={regionalPropertyHomepage.mainSubTitile.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>regional Property button</Form.Label>
                                            <Form.Control type="text" value={regionalPropertyHomepage.button.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">property Counter</h5>
                        </div>
                        <CustomToggle eventKey="4"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Counter maintitle</Form.Label>
                                            <Form.Control type="text" value={propertyCounter.mainTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Counter subtitle</Form.Label>
                                            <Form.Control type="text" value={propertyCounter.mainSubTitile.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">property cities</h5>
                        </div>
                        <CustomToggle eventKey="5"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property cities maintitle</Form.Label>
                                            <Form.Control type="text" value={metroCities.mainTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property cities subtitle</Form.Label>
                                            <Form.Control type="text" value={metroCities.mainSubTitle.slice(0, 60)} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        </div>
    )
}

export default Home
