import AdvanceTable from "theme-components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "theme-components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "theme-components/common/advance-table/AdvanceTableWrapper";

const columns = [
    {
        accessor: 'sn',
        Header: 'S.N'
    },
    {
        accessor: 'name',
        Header: 'Name'
    },
    {
        accessor: 'owner',
        Header: ''
    },
];

const data = [
    {
        sn: "1",
        name: '	kamla Niwas',
        owner: 'owner'
    },
    {
        sn: "2",
        name: '	Radha Niwas',
        owner: 'Tenants/owner'
    },
    {
        sn: "3",
        name: 'Shanti Niwas',
        owner: 'owner'
    },
    {
        sn: "4",
        name: 'Koshi Niwas',
        owner: 'Tenants'
    },
    {
        sn: "5",
        name: 'Komal Niwas',
        owner: 'owner'
    },
    {
        sn: "6",
        name: 'Pocket A',
        owner: 'Tenants'
    },
    {
        sn: "7",
        name: 'Pocket B',
        owner: 'Tenants/owner'
    },
];
export default function AdvanceTableExample() {
    return (
        <AdvanceTableWrapper columns={columns} data={data} sortable pagination perPage={5}>
            <AdvanceTable table headerClassName="bg-200 text-900 text-nowrap align-middle" rowClassName="align-middle white-space-nowrap"
                tableProps={{ bordered: true, striped: false, hover: true, size: 'sm', className: 'fs--1 mb-0 overflow-hidden fw-light' }} />
            <div className="mt-3">
                <AdvanceTablePagination table />
            </div>
        </AdvanceTableWrapper>
    )
}