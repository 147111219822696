import React from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { getLogin } from '../../services/apis/login-service';
import { tostStyle } from '../../helpers/utils';
import { LoginValidation } from 'services/validation/form-validation';
import { useLoading } from '../../context/loading-context';

const LoginForm = () => {

  const { loading, setLoading } = useLoading();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const homeNavigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    const payLoad = { email: data.email, password: data.password };
    const response = await getLogin(payLoad);

    if (response != null) {
      const { isSuccess, successMessage, errorMessages } = response;
      if (!isSuccess) { errorMessages.forEach(error => { toast.error(error.message, tostStyle); }); }
      else {
        const { token } = response.data;
        localStorage.setItem("token", token);
        toast.success(successMessage, tostStyle);
        homeNavigate('/');
      }
    }
    setLoading(false);
  }
  const errorParagraph = <p className='text-danger mt-1 fs--1'>{LoginValidation.requiredError}</p>
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="email" name="email" {...register("email", LoginValidation.required)} />
        {errors.email && errors.email.type === "required" && errorParagraph}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="password" name="password" {...register("password", LoginValidation.required)} />
        {errors.password && errors.password.type === "required" && errorParagraph}
      </Form.Group>

      <Row className="justify-content-end align-items-center">
        <Col xs="auto">
          <Link className="fs--1 mb-0" to="/forgot-password">Forgot Password?</Link>
        </Col>
      </Row>
      <Form.Group>

        <Button type="submit" color="primary" className="mt-3 w-100 d-flex justify-content-center align-items-center">
          Log in
          {
            loading ? <Spinner animation="border" variant="light" size='sm' className='ms-2' /> : ""
          }
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
