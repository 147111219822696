import AppSetting from '../../appConfig.json';
import axios from 'axios';
import { httpHeadersWithToken } from '../../helpers/utils';
import { exceptionHandler } from 'services/validation/api-handler';

export const RegisterUser = async (payLoad) => {
    try {
        const url = AppSetting.SERVER_URL + "/UserAccount/Register";
        const res = await axios.post(url, payLoad, httpHeadersWithToken);
        return res.data;
    }
    catch (err) {
        return exceptionHandler(err);
    }
}

export const GetUserById = async (id) => {
    try {
        const url = AppSetting.SERVER_URL + `/User/${id}`;
        const res = await axios.get(url, httpHeadersWithToken);
        return res.data;
    } catch (err) {

        return exceptionHandler(err);
    }
}

export const UpdateProfile = async (payLoad) => {
    try {
        const url = AppSetting.SERVER_URL + "/User/UpdateProfile";
        const res = await axios.post(url, payLoad, httpHeadersWithToken);
        return res.data;
    } catch (err) {

        return exceptionHandler(err);
    }
}