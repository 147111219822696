import React, { useState } from 'react';
import appTheme from "../../app-theme.json";
import { Form, Row, Col, Button, Image, Card, Accordion, useAccordionButton } from "react-bootstrap";
import { FaCheck, FaBan, FaEdit } from "react-icons/fa";


const PropertyDetails = () => {

    const [nearProperties, setNearProperties] = useState(false);
    const [sendMessage, setSendMessage] = useState(false);

    const editNearProperties = () => { setNearProperties(true) }
    const cancelNearProperties = () => { setNearProperties(false) }
    const editSendMessage = () => { setSendMessage(true) }
    const cancelSendMessage = () => { setSendMessage(false) }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button size="sm" className="admin_btn"
                variant='falcon-primary'
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    const { propertyDetails, ownerDetails } = appTheme.propertySearchDetails;

    return (
        <div className="main_border">

            <Accordion defaultActiveKey="0" className="accordion_box">
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">property Details label</h5>
                        </div>
                        <CustomToggle eventKey="1"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>apartment Name label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.apartmentName} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>No. of Bedroom label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.bedroom} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Apartment Type label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.apartmentType} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>floor label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.floor} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>total floor label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.totalFloor} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>No. of Balcony label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.balcony} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Age label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.propertyAge} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>built Up Area label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.builtUpArea} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>facing label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.facing} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>No. of Bathroom label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.bathroom} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>furnishing label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.furnishing} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>water Supply label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.waterSupply} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property Shown label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.propertyShown} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>parking label</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.parking} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">property Details buttons</h5>
                        </div>
                        <CustomToggle eventKey="2"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property discription</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.discription} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property overview</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.overview} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property amenities</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.amenities} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>property map</Form.Label>
                                            <Form.Control type="text" value={propertyDetails.map} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">owner Details</h5>
                        </div>
                        <CustomToggle eventKey="3"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>form title</Form.Label>
                                            <Form.Control type="text" value={ownerDetails.formTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>form button</Form.Label>
                                            <Form.Control type="text" value={ownerDetails.formButton} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">Near Properties label</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            nearProperties ?
                                <div className="update_value">
                                    <Form.Control type="text" value={ownerDetails.propertylistTitle} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelNearProperties}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{ownerDetails.propertylistTitle}</p>
                                    <Button size="sm" className="admin_btn" onClick={editNearProperties}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label">send message button</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            sendMessage ?
                                <div className="update_value">
                                    <Form.Control type="text" value={ownerDetails.submitButton} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelSendMessage}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{ownerDetails.submitButton}</p>
                                    <Button size="sm" className="admin_btn" onClick={editSendMessage}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default PropertyDetails
