import React from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';

const EditProfile = () => {
    return (
        <>
            <Card>
                <Card.Header as="h5">Edit Profile</Card.Header>
                <Card.Body className="bg-light">
                    <Form>
                        <Row className="mb-3 g-3">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter First Name" />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Last Name" />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label>Mobile No.</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Mobile NO." />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label>Role</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Select Role</option>
                                        <option value="1">Backend user</option>
                                        <option value="2">Moderate user</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3 mt-0 mt-md-4">
                                <Form.Group >
                                    <Form.Label>Gender</Form.Label>
                                    <br />
                                    <Form.Check inline type='radio' label='Male' name='gender' />
                                    <Form.Check inline type='radio' label='Female' name='gender' />
                                    <Form.Check inline type='radio' label='Others' name='gender' />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button variant="primary" type="submit" className="mx-2">Rest Password</Button>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}

export default EditProfile
