import React, { useState } from 'react';
import { Row, Col, Form, Button, Card, Accordion, useAccordionButton } from 'react-bootstrap';
import { FaEdit, FaCheck, FaBan } from "react-icons/fa";

import appTheme from "../../app-theme.json";

const Profile = () => {

    const [updateTitle, setUpdateTitle] = useState(false);
    const [updateEditButton, setUpdateEditButton] = useState(false);

    const editTitle = () => { setUpdateTitle(true) }
    const cancelTitle = () => { setUpdateTitle(false) }
    const editEditButton = () => { setUpdateEditButton(true) }
    const cancelEditButton = () => { setUpdateEditButton(false) }

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <Button size="sm" className="admin_btn"
                variant='falcon-primary'
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    const { profilePage } = appTheme;

    return (
        <div className="main_border">

            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label"> page title</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateTitle ?
                                <div className="update_value">
                                    <Form.Control type='text' value={profilePage.title} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelTitle}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{profilePage.title}</p>
                                    <Button size="sm" className="admin_btn" onClick={editTitle}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Form className="custom_card form_box">
                <Row>
                    <Col md={3}>
                        <div className="label_box">
                            <h5 className="label"> edit button</h5>
                        </div>
                    </Col>
                    <Col md={9}>
                        {
                            updateEditButton ?
                                <div className="update_value">
                                    <Form.Control type='text' value={profilePage.button} />
                                    <div className="update_btn_box">
                                        <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        <Button size="sm" className="admin_btn" onClick={cancelEditButton}><FaBan /> Cancel</Button>
                                    </div>
                                </div>
                                : <div className="value_box">
                                    <p className="value_text">{profilePage.button}</p>
                                    <Button size="sm" className="admin_btn" onClick={editEditButton}><FaEdit /> Edit</Button>
                                </div>
                        }
                    </Col>
                </Row>
            </Form>
            <Accordion defaultActiveKey="0" className="accordion_box">
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">image modal label</h5>
                        </div>
                        <CustomToggle eventKey="1"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal Title</Form.Label>
                                            <Form.Control type="text" value={profilePage.modalTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>change Button</Form.Label>
                                            <Form.Control type="text" value={profilePage.changeButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>submit Button</Form.Label>
                                            <Form.Control type="text" value={profilePage.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">Personal Information label</h5>
                        </div>
                        <CustomToggle eventKey="2"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" value={profilePage.personalInfo.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>firstName</Form.Label>
                                            <Form.Control type="text" value={profilePage.personalInfo.firstName} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>lastName</Form.Label>
                                            <Form.Control type="text" value={profilePage.personalInfo.lastName} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>email</Form.Label>
                                            <Form.Control type="text" value={profilePage.personalInfo.email} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>mobile</Form.Label>
                                            <Form.Control type="text" value={profilePage.personalInfo.mobile} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal Title</Form.Label>
                                            <Form.Control type="text" value={profilePage.personalInfo.modalTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>cancel Button</Form.Label>
                                            <Form.Control type="text" value={profilePage.personalInfo.cancelButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>submit Button</Form.Label>
                                            <Form.Control type="text" value={profilePage.personalInfo.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card className='mb-2'>
                    <Card.Header>
                        <div className="label_box">
                            <h5 className="label">address label</h5>
                        </div>
                        <CustomToggle eventKey="3"><FaEdit /> Edit</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" value={profilePage.address.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>country</Form.Label>
                                            <Form.Control type="text" value={profilePage.address.country} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>state</Form.Label>
                                            <Form.Control type="text" value={profilePage.address.state} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>city</Form.Label>
                                            <Form.Control type="text" value={profilePage.address.city} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>pincode</Form.Label>
                                            <Form.Control type="text" value={profilePage.address.pincode} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>modal Title</Form.Label>
                                            <Form.Control type="text" value={profilePage.address.modalTitle} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>cancel Button</Form.Label>
                                            <Form.Control type="text" value={profilePage.address.cancelButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>submit Button</Form.Label>
                                            <Form.Control type="text" value={profilePage.address.submitButton} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="update_btn_box">
                                            <Button size="sm" className="admin_btn"><FaCheck /> Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        </div>
    )
}

export default Profile
