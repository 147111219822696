import AppSetting from '../../appConfig.json';
import axios from 'axios';
import { httpHeaders } from '../../helpers/utils';
import { exceptionHandler } from 'services/validation/api-handler';

export const getLogin = async (payLoad) => {
  try {
    const url = AppSetting.SERVER_URL + "/Authentication/Login";
    const res = await axios.post(url, payLoad, httpHeaders);
    return res.data;
  } catch (err) {
    return exceptionHandler(err);
  }
};
