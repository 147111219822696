import classNames from 'classnames';
import ProductProvider from 'theme-components/theme/e-commerce/ProductProvider';
import CourseProvider from 'theme-components/theme/e-learning/CourseProvider';
import Footer from 'theme-components/footer/Footer';
import NavbarTop from 'theme-components/navbar/top/NavbarTop';
import NavbarVertical from 'theme-components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const { config: { navbarPosition } } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) { element.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
      }
    }, 0);
  }, []);

  useEffect(() => { window.scrollTo(0, 0); }, [pathname]);

  const isTokenExpired = () => {
    const decodeToken = jwtDecode(localStorage.getItem('token'));
    const currentDate = new Date();
    if (decodeToken.exp * 1000 < currentDate.getTime()) {
      localStorage.removeItem('token');
      return true;
    }
    return false;
  }

  if (localStorage.getItem('token') == null || isTokenExpired()) {
    return <Navigate replace to="/login" />;
  }
  else {
    return (
      <div className='container-fluid'>
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        <ProductProvider>
          <CourseProvider>
            <div className={classNames('content', { 'pb-0': isKanban })}>
              <NavbarTop />
              {/*------ Main Routes ------*/}
              <Outlet />
              {/* {!isKanban && <Footer />} */}
            </div>
          </CourseProvider>
        </ProductProvider>
      </div>
    );
  }
};

export default MainLayout;
