import ActionButton from 'theme-components/common/ActionButton';
import React from 'react';
import { Button, Card, Form, Table, Row, Col } from 'react-bootstrap';

const PropertyTypes = () => {
    return (
        <>
            <Row className='gx-3'>
                <Col md={6} className='mb-3'>
                    <Card>
                        <Card.Body>
                            <Form className='d-flex align-items-center justify-content-between'>
                                <Form.Group className="mb-3 flex-1" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='fs--1'>Property types</Form.Label>
                                    <Form.Control type="text" placeholder="Property types" size='sm' />
                                </Form.Group>
                                <Button variant='success' size='sm' className='inline-block mt-3 ms-3'>Add</Button>
                            </Form>
                            <Table responsive hover size='sm' className='fs--1 fw-light mt-3'>
                                <thead>
                                    <tr>
                                        <th scope="col">S.N</th>
                                        <th scope="col">Types</th>
                                        <th className="text-end" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td>1</td>
                                        <td>1 BHK</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>2 BHK</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>3 BHK</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>1 BHK</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>1 RK</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} className='mb-3'>
                    <Card>
                        <Card.Body>
                            <Form className='d-flex align-items-center justify-content-between'>
                                <Form.Group className="mb-3 flex-1" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='fs--1'>Categories</Form.Label>
                                    <Form.Control type="text" placeholder="Categories" size='sm' />
                                </Form.Group>
                                <Button variant='success' size='sm' className='inline-block mt-3 ms-3'>Add</Button>
                            </Form>

                            <Table responsive hover size='sm' className='fs--1 fw-light mt-3'>
                                <thead>
                                    <tr>
                                        <th scope="col">S.N</th>
                                        <th scope="col">Types</th>
                                        <th className="text-end" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td>1</td>
                                        <td>Basic</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Advance</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Premium</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Advance</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Basic</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} className='mb-3'>
                    <Card>
                        <Card.Body>
                            <Form className='d-flex align-items-center justify-content-between'>
                                <Form.Group className="mb-3 flex-1" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='fs--1'>Furnishing</Form.Label>
                                    <Form.Control type="text" placeholder="Furnishing" size='sm' />
                                </Form.Group>
                                <Button variant='success' size='sm' className='inline-block mt-3 ms-3'>Add</Button>
                            </Form>

                            <Table responsive hover size='sm' className='fs--1 fw-light mt-3'>
                                <thead>
                                    <tr>
                                        <th scope="col">S.N</th>
                                        <th scope="col">Types</th>
                                        <th className="text-end" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td>1</td>
                                        <td>Fully Furnished</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Semi Furnished</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Unfurnished</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Fully Furnished</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Semi Furnished</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} className='mb-3'>
                    <Card>
                        <Card.Body>
                            <Form className='d-flex align-items-center justify-content-between'>
                                <Form.Group className="mb-3 flex-1" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='fs--1'>Area</Form.Label>
                                    <Form.Control type="text" placeholder="Area in sqft" size='sm' />
                                </Form.Group>
                                <Button variant='success' size='sm' className='inline-block mt-3 ms-3'>Add</Button>
                            </Form>

                            <Table responsive hover size='sm' className='fs--1 fw-light mt-3'>
                                <thead>
                                    <tr>
                                        <th scope="col">S.N</th>
                                        <th scope="col">Types</th>
                                        <th className="text-end" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td>1</td>
                                        <td>500 sqft</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>700 sqft</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>800 sqft</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>900 sqft</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>600 sqft</td>
                                        <td className="text-end">
                                            <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className='mb-3'>
                    <Card>
                        <Card.Body>
                            <Form>
                                <Row className='gx-2'>
                                    <Col md={6} className='mb-3'>
                                        <Form.Group>
                                            <Form.Label className='fs--1'>Property types</Form.Label>
                                            <Form.Select size="sm">
                                                <option value="" hidden>types</option>
                                                <option value="1 BHK" >1 BHK</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <Form.Group>
                                            <Form.Label className='fs--1'>Categories</Form.Label>
                                            <Form.Select size="sm">
                                                <option value="" hidden>Categories</option>
                                                <option value="1 BHK" >Basic</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <Form.Group>
                                            <Form.Label className='fs--1'>Furnishing</Form.Label>
                                            <Form.Select size="sm">
                                                <option value="" hidden>Furnishing</option>
                                                <option value="1 BHK" >Fully Furnished</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <Form.Group>
                                            <Form.Label className='fs--1'>Area</Form.Label>
                                            <Form.Select size="sm">
                                                <option value="" hidden>Area</option>
                                                <option value="1 BHK" >500 sqft</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                                            <Form.Group className="mb-3 flex-1" controlId="exampleForm.ControlInput1">
                                                <Form.Label className='fs--1'>Price Slab</Form.Label>
                                                <div className="d-flex flex-column flex-sm-row">
                                                    <Form.Control type="text" placeholder="Minimum price" size='sm' />
                                                    <Form.Control type="text" placeholder="Maximum price" size='sm' className='ms-sm-3 mt-3 mt-sm-0' />
                                                </div>
                                            </Form.Group>
                                            <Button variant='success' size='sm' className='inline-block mt-sm-3 ms-sm-3'>Add</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


        </>
    )
}

export default PropertyTypes
