import React from 'react';
import { Button, Card, Col, Image, Row, Table } from 'react-bootstrap';
import gallery1 from '../../assets/img/gallery/gallery1.jpg';
import gallery3 from '../../assets/img/gallery/gallery3.jpg';
import gallery4 from '../../assets/img/gallery/gallery4.jpg';
import FalconLightBoxGallery from 'theme-components/common/FalconLightBoxGallery';
import beach from '../../assets/video/beach.mp4';

const PreviewProperty = () => {
  const images = [gallery1, gallery3, gallery4];
  return (
    <>
      <Row className="gx-3">
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <h6>Property Details</h6>
              <hr />
              <Table responsive hover size="sm" className="fs--1 fw-light mt-3">
                <tbody>
                  <tr>
                    <td>Property Name </td>
                    <td className="text-end">Kamla Niwas</td>
                  </tr>
                  <tr>
                    <td>Apartment Type</td>
                    <td className="text-end">Apartment</td>
                  </tr>
                  <tr>
                    <td>BHK Type</td>
                    <td className="text-end">2 BHK</td>
                  </tr>
                  <tr>
                    <td>Floor</td>
                    <td className="text-end">3</td>
                  </tr>
                  <tr>
                    <td>Total Floor</td>
                    <td className="text-end">4</td>
                  </tr>
                  <tr>
                    <td>Property Age</td>
                    <td className="text-end">1 to 3 Years</td>
                  </tr>
                  <tr>
                    <td>Facing</td>
                    <td className="text-end">South</td>
                  </tr>
                  <tr>
                    <td>Built Up Area</td>
                    <td className="text-end">520. sqft</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <h6>Provide rental details about your property</h6>
              <hr />
              <Table responsive hover size="sm" className="fs--1 fw-light mt-3">
                <tbody>
                  <tr>
                    <td>Property available for</td>
                    <td className="text-end">Only rent</td>
                  </tr>
                  <tr>
                    <td>Expected Rent</td>
                    <td className="text-end">10000</td>
                  </tr>
                  <tr>
                    <td>Expected Deposit</td>
                    <td className="text-end">50000</td>
                  </tr>
                  <tr>
                    <td>Monthly Maintenance</td>
                    <td className="text-end">Maintenance Extra</td>
                  </tr>
                  <tr>
                    <td>Available From</td>
                    <td className="text-end">1 jun 2023</td>
                  </tr>
                  <tr>
                    <td>Preferred Tenants</td>
                    <td className="text-end">Family</td>
                  </tr>
                  <tr>
                    <td>Furnishing</td>
                    <td className="text-end">Fully Furnished</td>
                  </tr>
                  <tr>
                    <td>Parking</td>
                    <td className="text-end">Bike and Car</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td className="text-end">this is for rent</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <h6>Property types</h6>
              <hr />
              <Table responsive hover size="sm" className="fs--1 fw-light mt-3">
                <tbody>
                  <tr>
                    <td>City </td>
                    <td className="text-end">Mumbai</td>
                  </tr>
                  <tr>
                    <td>Residential</td>
                    <td className="text-end">Rent</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <h6>Locality Details</h6>
              <hr />
              <Table responsive hover size="sm" className="fs--1 fw-light mt-3">
                <tbody>
                  <tr>
                    <td>City</td>
                    <td className="text-end">New Delhi</td>
                  </tr>
                  <tr>
                    <td>Locality</td>
                    <td className="text-end">Laxmi Nagar</td>
                  </tr>
                  <tr>
                    <td>Landmark / Street</td>
                    <td className="text-end">Sai Mandir</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <h6>Provide additional details</h6>
              <hr />
              <Table responsive hover size="sm" className="fs--1 fw-light mt-3">
                <tbody>
                  <tr>
                    <td>Bathroom(s)</td>
                    <td className="text-end">2</td>
                  </tr>
                  <tr>
                    <td>Balcony</td>
                    <td className="text-end">1</td>
                  </tr>
                  <tr>
                    <td>Water Supply</td>
                    <td className="text-end">Corporation</td>
                  </tr>
                  <tr>
                    <td> Who will show the property</td>
                    <td className="text-end">Neighbours</td>
                  </tr>
                  <tr>
                    <td>Secondary Number</td>
                    <td className="text-end">90112345678</td>
                  </tr>
                  <tr>
                    <td>Directions Tip</td>
                    <td className="text-end">Come through sai madir street</td>
                  </tr>
                  <tr>
                    <td> available amenities</td>
                    <td className="text-end">Lift,Internet Services</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <h6>Gallery</h6>
              <hr />
              <FalconLightBoxGallery images={images}>
                {setImgIndex => (
                  <Row className="g-2">
                    <Col md={6}>
                      <Image
                        src={images[0]}
                        fluid
                        rounded
                        className="cursor-pointer"
                        onClick={() => setImgIndex(0)}
                      />
                    </Col>
                    <Col md={6}>
                      <Image
                        src={images[1]}
                        fluid
                        rounded
                        className="cursor-pointer"
                        onClick={() => setImgIndex(1)}
                      />
                    </Col>
                    <Col md={6}>
                      <Image
                        src={images[2]}
                        fluid
                        rounded
                        className="cursor-pointer"
                        onClick={() => setImgIndex(2)}
                      />
                    </Col>
                    <Col md={6}>
                      <div style={{ height: '124px', overflow: 'hidden' }}>
                        <video controls width="100%" className="rounded">
                          <source src={beach} type="video/mp4" />
                        </video>
                      </div>
                    </Col>
                  </Row>
                )}
              </FalconLightBoxGallery>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} className="mb-3">
          <Card className="h-100">
            <Card.Body className="d-flex justify-content-center">
              <Button variant="primary" size="sm" className="mx-2">
                Approved
              </Button>
              <Button variant="danger" size="sm" className="mx-2">
                Decline
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PreviewProperty;
