export const Messages = {
    ServerError: "Unable to connect with server. Please try again",
    TokenError: "Unauthorized token",
    NotFound: "The server cannot find the requested resource",
    Failed: "Request failed"
}

export const AddUserType = {
    required: "required",
    pattern: "pattern",
    minLength: "minLength"
}

export const AddUserMessage = {
    firstName: {
        required: "First Name field is required",
        pattern: "First Name can't be number",
        length: "First Name must be 3 charactor",
    },
    lastName: {
        required: "Last Name field is required",
        pattern: "Last Name can't be number",
        length: "Last Name must be 3 charactor",
    },
    email: {
        required: "Email field is required",
        pattern: "must be valid email",
    },
    mobile: {
        required: "mobile number is required",
        pattern: "mobile number can be 10 charactor",
    },
    role: {
        required: "Role field is required",
    },

}

export const UserListModal = {
    required: "reason field is required",
    minLength: "reason atleast 5 charactor"
}

export const ConfirmationMessage = {
    message_1: "Are you sure you want to",
    message_2: "user ? If yes, please provide reson below",
}