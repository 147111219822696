import React from 'react';
import "../../assets/scss/custom-loader.scss";

const CustomLoader = () => {

    return (
        <>
            <span className='loader'></span>
        </>
    )
}

export default CustomLoader
