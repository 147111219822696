export const AddValidation = {
    name: { required: true, minLength: 3, pattern: /^[A-Za-z]+$/i },
    email: { required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]+$/i },
    mobile: { pattern: /^[0-9]{10}$/i },
    mobileRequired: { required: true, pattern: /^[0-9]{10}$/i },
    required: { required: true }
}

export const ConfirmModalValidation = {
    reason: { required: true, minLength: 5, }
}

export const LoginValidation = {
    required: { required: true, },
    requiredError: "This is required",
} 