
import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router-dom';
import { GetAll } from '../../services/apis/user-account-service';
import { tokenHandler } from '../../services/validation/api-handler';
import CustomLoader from 'theme-components/common/custom-loader';
import { useLoading } from "../../context/loading-context";
import { Button, Card, Col, InputGroup, Row, Table } from 'react-bootstrap';
import { faBan, faCheck, faEdit, faLock, faPlus, faSearch, faTimes, faUnlock, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from 'app-components/user-list/Modal';
import RedirectModal from 'app-components/redirect-modal';
import { modelAction } from 'helpers/utils';
import { ConfirmationMessage } from 'helpers/messages';
import { Link } from 'react-router-dom';

const UserList = () => {

    const defaultGridModel = { currentPage: 0, pageSize: 0, result: [], totalRecords: 0 };
    const defaultModelState = { userId: null, action: null, show: false, message: null };
    const searchRef = useRef(null);

    const addUser = useNavigate();
    const login = useNavigate();

    const [grid, setGrid] = useState(defaultGridModel);
    const [pageCount, setpageCount] = useState(0);
    const [redirectModal, setRedirectModal] = useState(false);
    const [model, setModel] = useState(defaultModelState);
    const { loading, setLoading } = useLoading();
    const [payLoad, setPayLoad] = useState({ orderBy: null, orderBOrderByDescending: true, search: null, pageNumber: 1, pageSize: 10 });

    useEffect(() => { loadUsers(); }, []);
    useEffect(() => { loadUsers(); }, [payLoad]);

    // handle events for search, pagination and page size change

    const onSearch = async () => {
        const searchQuery = searchRef.current.value;
        if (searchQuery != null && searchQuery != "") { setPayLoad({ ...payLoad, search: searchQuery }); }
    }

    const onSearchClear = async () => {
        searchRef.current.value = null;
        setPayLoad({ ...payLoad, search: null });
    }

    const onPageChange = async (data) => { setPayLoad({ ...payLoad, pageNumber: data.selected + 1 }); };
    const onPageSizeChange = async (e) => { setPayLoad({ ...payLoad, pageSize: e.target.value }); }

    // Handle events for active/inactive, lock/unlock user

    const showModel = async (userId, action) => {
        const mgs = `${ConfirmationMessage.message_1} ${action.toLowerCase()} ${ConfirmationMessage.message_2}`;
        setModel({ ...model, action: action, userId: userId, show: true, message: mgs });
    }

    const hideModal = async (reloadGrid) => {
        setModel(defaultModelState);
        if (reloadGrid) { await loadUsers(); }
    }

    // Load users from Api as per givan payload

    const loadUsers = async () => {
        if (tokenHandler() == true || tokenHandler() == undefined) {
            setRedirectModal(true);
            setTimeout(() => {
                setRedirectModal(false);
                login("/login");
            }, 10000);
        }
        else {
            setLoading(true);
            const response = await GetAll(payLoad);
            if (response != null) {
                setpageCount(Math.ceil(response.data.totalRecords / payLoad.pageSize));
                setGrid(response.data);
            }
            setLoading(false);
        }
    };

    return (
        <>
            {redirectModal ? <RedirectModal show={redirectModal} /> : ""}
            <Card className='user-list'>
                <Card.Body>
                    <Card.Title as='h5' className='mb-3'> User List </Card.Title>
                    <Row>
                        <Col md={6} className='search-group'>
                            <InputGroup size="sm">
                                <Form.Control placeholder="Name, Email" ref={searchRef} />
                                {payLoad.search ? <FontAwesomeIcon icon={faTimes} className='clear-search' onClick={onSearchClear} /> : ""}
                                <Button variant="primary" onClick={onSearch}> <FontAwesomeIcon icon={faSearch} /></Button>
                            </InputGroup>
                        </Col>
                        <Col md={6} className='text-end'>
                            <Button variant="primary" size='sm' onClick={() => addUser("/usermanagement/adduser")}> <FontAwesomeIcon icon={faPlus} /> Add User</Button>
                        </Col>
                    </Row>

                    <Row className='grid-row'>
                        <Table hover responsive size="sm" className='mt-4'>
                            <thead className="bg-light">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th className='text-center'>Active</th>
                                    <th className='text-center'>Locked</th>
                                    <th className='text-end'>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? <tr><td colSpan="6" className="text-center"><CustomLoader /></td></tr> :
                                        grid.result.map((user) => {
                                            return (
                                                <tr key={user.userId}>
                                                    <td><FontAwesomeIcon icon={faUserCircle} className='grid-default-pic' /></td>
                                                    <td>{user.firstName} {user.lastName}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.role[0].roleName}</td>
                                                    <td className='text-center'>
                                                        {
                                                            user.isDeleted ?
                                                                <FontAwesomeIcon icon={faCheck} className='text-success cursor-pointer' onClick={() => showModel(user.userId, modelAction.RESTORE)} />
                                                                : <FontAwesomeIcon icon={faBan} className='text-danger cursor-pointer' onClick={() => showModel(user.userId, modelAction.DELETE)} />
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            user.isLocked ?
                                                                <FontAwesomeIcon icon={faLock} className='text-danger cursor-pointer' onClick={() => showModel(user.userId, modelAction.UNLOCK)} />
                                                                : <FontAwesomeIcon icon={faUnlock} className='text-warning cursor-pointer' onClick={() => showModel(user.userId, modelAction.LOCK)} />
                                                        }
                                                    </td>
                                                    <td className='text-end'>
                                                        <Link to={`/usermanagement/edituser/${user.userId}`}>
                                                            <FontAwesomeIcon icon={faEdit} className='text-info cursor-pointer' />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                }
                            </tbody>
                        </Table>
                    </Row>

                    <Row >
                        <Col className="mt-3">
                            <span className="d-none d-sm-inline-block text-muted">Total record found: <b>{grid.totalRecords}</b> | </span>
                            <div className="mb-0 mx-2 d-sm-inline-block">
                                <span className='d-sm-inline-block me-2 text-muted'>Rows per page : </span>
                                <p className='d-sm-inline-block '>
                                    <select className='w-auto form-select form-select-sm' onChange={onPageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            {
                                grid.totalRecords > 0 ? <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={5}
                                    pageRangeDisplayed={5}
                                    onPageChange={onPageChange}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                /> : ""
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {model.show ? <ConfirmModal options={model} onClose={hideModal} /> : ""}
        </>
    );
};

export default UserList;
